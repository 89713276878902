import * as React from 'react';
import classNames from 'classnames';
import {
  GoogleMapProps,
  IGoogleMapsImperativeActions,
} from '../GoogleMap.types';

import { getDataAttributes, isBrowser } from '../../../core/commons/utils';
import { useGoogleIFrame } from './hooks';
import { SKIN_BORDERS } from './skinParts';

import style from './style/GoogleMap.scss';

import googleMapHtml from '!!url-loader?limit=1!./assets/googleMap.html';
// @ts-ignore
import googleMapsScriptUrl from '!!url-loader?limit=1!./assets/google-map.min.js';

const getGoogleMapUrl = (urlQueries: GoogleMapProps['urlQueries']) => {
  const additionalParams = new URLSearchParams({
    googleMapsScriptPath: new URL(googleMapsScriptUrl).pathname,
  });
  if (isBrowser()) {
    additionalParams.append('origin', window.origin);
  }
  return `${googleMapHtml}?${urlQueries}&${additionalParams}`;
};

const GoogleMap: React.ForwardRefRenderFunction<
  IGoogleMapsImperativeActions,
  GoogleMapProps
> = (props, forwardRef) => {
  const {
    id,
    className,
    skin,
    urlQueries,
    mapData,
    translate,
    onUpdateCenter,
    onUpdateZoom,
    onMarkerClicked,
    onMapClicked,
    onMouseEnter,
    onMouseLeave,
  } = props;
  const SkinBorders = SKIN_BORDERS[skin];

  const url = getGoogleMapUrl(urlQueries);

  const [render, setRender] = React.useState(false);
  const [ref] = useGoogleIFrame(forwardRef, mapData, {
    onUpdateCenter,
    onUpdateZoom,
    onMarkerClicked,
    onMapClicked,
  });

  React.useEffect(() => {
    setRender(true);
  }, [url]);

  const title = translate!(
    'component_label',
    'COMPONENT_LABEL_googleMapsTitle',
    'Google Map',
  );

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      tabIndex={0}
      className={classNames(className, style[skin], style.wixIframe)}
    >
      {render && (
        <wix-iframe title={title} aria-label={title} data-src={url}>
          <SkinBorders />
          <div
            id={`mapContainer_${id}`}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className={style.mapContainer}
          >
            <iframe
              ref={ref}
              title={title}
              aria-label={title}
              data-src={url}
              width="100%"
              height="100%"
              frameBorder="0"
              scrolling="no"
              allowFullScreen
            />
          </div>
        </wix-iframe>
      )}
    </div>
  );
};

export default React.forwardRef(GoogleMap);
